<template>
  <v-scale-transition>
    <kits-panel :kits-items="technicalKits">
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Technical Kits
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
export default {
  components: {
    KitsPanel: () => import('@/components/KitsPanel'),
  },
  data () {
    return {
      technicalKits: [
        {
          icon: 'mdi-swap-horizontal',
          path: '/technical/change_dreg',
          title: 'Change DREG',
          desc: '',
        },
        {
          icon: 'mdi-credit-card-refund-outline',
          path: '/technical/credit_adjustment',
          title: 'Credit Adjustment',
          desc: '',
        },
        {
          icon: 'mdi-account-reactivate-outline',
          path: '/technical/reactivate_dealer',
          title: 'Reactivate Dealer',
          desc: '',
        },
        {
          icon: 'mdi-account-box-multiple',
          path: '/technical/revoke_basic_dealer',
          title: 'Revoke Basic Dealer',
          desc: '',
        },
      ],
    }
  },
}
</script>
